import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { Flex, Box } from 'rebass/styled-components'
import { rgba } from 'polished'
import BackgroundImage from 'gatsby-background-image'
import { Link } from 'gatsby-plugin-i18n'

const HeroTitle = styled.h1`
  color: ${rgba('#171544', 0.9)};
`

const StyledTitle = styled(HeroTitle)`
  margin-bottom: 1rem;
  font-size: 2rem;
`

const StyledText = styled.div`
  margin-bottom: 1rem;
  font-size: 1.25rem;
`

const StyledBackgroundImage = styled(BackgroundImage)`
  min-height: 50vh;
  display: flex;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (min-width: 768px) {
    min-height: 100vh;
  }
`

const Hero = ({ image, title, text, textColor, ctaLink, ctaLabel, theme }) => {
  return (
    <Box sx={{ minHeight: ['50vh', '80vh', '100vh'] }}>
      <StyledBackgroundImage
        tag="div"
        fluid={
          image && image.childImageSharp ? image.childImageSharp.fluid : image
        }
        backgroundColor={theme.colors.heroBgColor}
      >
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          width={[1, '50%']}
          mt="10%"
          ml={['30%', '45%']}
          mr="auto"
          p={4}
        >
          {title && (
            <StyledTitle as="p" style={{ color: textColor ? textColor : '' }}>
              {title}
            </StyledTitle>
          )}

          {text && (
            <StyledText
              style={{ color: textColor ? textColor : '' }}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}

          {ctaLink && ctaLabel && (
            <Link
              style={{
                display: 'inline-block',
                padding: '0.5rem 2rem',
                backgroundColor: theme.colors.primary,
                borderRadius: '3rem',
                color: 'white',
                fontSize: '1.25rem',
                textDecoration: 'none',
              }}
              to={ctaLink}
            >
              {ctaLabel}
            </Link>
          )}
        </Flex>
      </StyledBackgroundImage>
    </Box>
  )
}

Hero.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  text: PropTypes.string,
  textColor: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaLabel: PropTypes.string,
  theme: PropTypes.object,
}

export default withTheme(Hero)
