import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { withI18next } from 'gatsby-plugin-i18n'

import Layout from '../components/Layout'
import Hero from '../components/Hero'
import { draftjsContentToHTML } from '../utils/draftjsContentToHTML'

const HomepagePage = ({ data, pageContext }) => {
  const { page } = data
  const { language } = pageContext
  const { t } = useTranslation('index')

  return (
    <Layout language={language}>
      <Hero
        title={
          (page && page.title) ||
          t('index:hero.title', {
            defaultValue: 'Discover our workshops',
          })
        }
        text={page && page.content && draftjsContentToHTML(page.content)}
        image={(page && page.image && page.image.local) || null}
        ctaLink="/workshops"
        ctaLabel={t('index:hero.ctaButtonLabel', {
          defaultValue: 'See workshops',
        })}
      />
    </Layout>
  )
}

HomepagePage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default withI18next()(HomepagePage)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { in: ["index"] } }
    ) {
      ...LocalLocaleFragment
    }
    page: pages(template: { eq: "homepage" }, language: { eq: $language }) {
      title
      content
      image {
        local {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
